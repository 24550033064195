import React, { useEffect, useRef , useState } from 'react';


import './Button.scss'

import fly from './images/flip/BTH-Dog-1 (1).png';
import { Grid } from '@mui/material';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import footlogo from './images/flip/gottonfoot.png'
import { BsTwitterX } from "react-icons/bs";

import { FaTelegramPlane } from "react-icons/fa";



// Register GSAP plugin
gsap.registerPlugin(ScrollTrigger);

function Footer() {
  const socialsRef = useRef(null);
  const imagesRef = useRef([]);
  const flyImageRef = useRef(null);
  const buttonRef = useRef(null);

  const [hover, setHover] = useState(false); // State for hover animation
  const [hovertelegram, setHovertelegram] = useState(false); // State for hover animation


  useEffect(() => {
    // Socials title animation
    gsap.from(socialsRef.current, {
      scrollTrigger: {
        trigger: socialsRef.current,
        start: 'top 80%',
        toggleActions: 'play none none reverse',
      },
      opacity: 0,
      y: 100,
      rotationX: 90,
      duration: 1.2,
    });

    // Social icons animation
    imagesRef.current.forEach((img, index) => {
      gsap.from(img, {
        scrollTrigger: {
          trigger: img,
          start: 'top 90%',
          toggleActions: 'play none none reverse',
        },
        opacity: 0,
        x: index % 2 === 0 ? -100 : 100,
        rotationY: 180,
        duration: 1.5,
        delay: index * 0.2,
      });
    });

    // Flying image animation
    gsap.from(flyImageRef.current, {
      scrollTrigger: {
        trigger: flyImageRef.current,
        start: 'top 80%',
        toggleActions: 'play none none reverse',
      },
      opacity: 0,
      scale: 0.5,
      rotationY: 360,
      duration: 1.5,
    });

    // Button animation
    gsap.from(buttonRef.current, {
      scrollTrigger: {
        trigger: buttonRef.current,
        start: 'top 80%',
        toggleActions: 'play none none reverse',
      },
      opacity: 0,
      y: 50,
      scale: 0.5,
      duration: 1.2,
    });
  }, []);

  return (
    <div className='footerzz'>
    
      
    
     
      <Grid container>
        <Grid item md={12} xs={12} lg={6}>
        
          <img
            src={fly}
            style={{ width: '90%', position: 'relative', right:"10px" }}
            className='vert-move'
           
          />
           <br /> <br /> <br /> <br /> <br /> <br />
        </Grid>
        <Grid item md={12} xs={12} lg={6}>
           <br /> <br /> <br /> <br /> <br />
         
          <div style={{ color: '#fff' }}>
        <h1 ref={socialsRef} style={{ textAlign: 'center', fontSize: '130px', fontWeight: '900' }} className='border_socials'>
          SOCIALS
        </h1>
        <br /> 
        <Grid item md={12} lg={12} style={{ display: 'flex' }} className='centeritall'>
         
        <div 
      style={{
        backgroundColor:'#fff',
        backgroundSize: 'cover',
        backgroundPosition: 'center', // Ensures the image is centered
        width: '70px', 
        height: '70px', 
        borderRadius: '50%', 
        border:"3px solid #000" ,
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
   
   
        transition: 'transform 0.3s ease',
        transform: hover ? 'scale(1.1)' : 'scale(1)' // Animation on hover
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <a href='/twitter' target='_blank' rel="noopener noreferrer">
        <BsTwitterX 
          style={{
            color: hover ? 'black' : 'black', // Change color on hover
            fontSize: '33px',
            transition: 'color 0.3s ease',
            textAlign: 'center',
            position:"relative",
            top:"4px" // Ensures the text or icon is centered
          }} 
        />
      </a>
    </div>

    &nbsp;&nbsp;
    <div 
      style={{
       backgroundColor:'#fff',
        backgroundSize: 'cover',
        backgroundPosition: 'center', // Ensures the image is centered
        width: '70px', 
        height: '70px', 
        borderRadius: '50%', 
        border:"3px solid #000" ,
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
   
        transition: 'transform 0.3s ease',
        transform: hovertelegram ? 'scale(1.1)' : 'scale(1)' // Animation on hover
      }}
      onMouseEnter={() => setHovertelegram(true)}
      onMouseLeave={() => setHovertelegram(false)}
    >
      <a href='https://t.me/GOTTON_meme' target='_blank' rel="noopener noreferrer">
        <FaTelegramPlane 
          style={{
            color: hovertelegram ? 'black' : 'black', // Change color on hover
            fontSize: '38px',
            transition: 'color 0.3s ease',
            textAlign: 'center',
            position:"relative",
            top:"3px",
            right:"1px"
            
            // Ensures the text or icon is centered
          }} 
        />
      </a>
    </div>



 

        </Grid>
        <br /> 
        <h1  style={{ textAlign: 'center', fontSize: '30px', fontWeight: '200' , color:"#fff" }} className='border_socials'>Memecoin Catcher</h1>
        <br/>
        <Grid item md={12} lg={12} style={{ display: 'flex' }} className='centeritall'>
      

        <a href=''>
        <button class="button" type="button">
  <span class="button-outline">    
    <span class="button-shadow">
      <span class="button-inside">
        <span class="button-text visually-hidden"> MINI APP</span>
        <span class="button-text-characters-container" aria-hidden="true"></span>
      </span>
    </span>
  </span>
</button>
        </a>
 

        </Grid>
         
      
      </div>
          <br />
        
        </Grid>
      </Grid>
      <div className='banner_mainf '>
    
        <div className=''>
        <div className='foot_under'>  <Grid container>
       <Grid item md={12} lg={3} xs={12} sm={12}>
        <div style={{display:"flex"}}>
        <img src={footlogo} className='footlogo '/>

        
      
       
        </div><br/>
       
         
</Grid>


<Grid item md={4} lg={4} xs={12} sm={12}>

       
        
</Grid>
<Grid item md={1} lg={1} xs={12} sm={12}>

        
</Grid>
<Grid item md={4} lg={4} xs={12} sm={12}>
<div style={{display:"flex"}}>
<h1 className="shima_headfs" >
support@gotton.meme
           </h1>
        
           <br/>
       
      
       
        </div>
   
      
      
        
           <br/>
        
</Grid>
       </Grid></div>

        </div>
    
       
      </div>
    </div>
  );
}

export default Footer;
