import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import './styles.css';

// Import images
import one from "./l1.png";
import two from "./p1.png";

import p2 from "./p2.png";
import p3 from "./p3.png";

import p4 from "./p4.webp";
import p5 from "./p5.png";

import p6 from "./p6.png";
import p7 from "./p7.jpeg";

import arrowleft from './arrowleft.png';
import arrowright from './arrowright.png';

// Import required modules
import { Navigation, Autoplay } from 'swiper/modules';

export default function App() {
  return (
    <div > {/* Custom Navigation Buttons */}
    <div  className='container'>

          <div className="custom-navigation">
      <button className="custom-prev">
        <img src={arrowleft} alt="Previous" className='image' />
      </button>
      <button className="custom-next">
        <img src={arrowright} alt="Next"  className='image' />
      </button>
    </div> 
    </div>
 
      <Swiper
       navigation={{
        nextEl: '.custom-next',
        prevEl: '.custom-prev',
      }}
      modules={[Navigation, Autoplay]}
      autoplay={{
        delay: 3000, // Time in milliseconds between slides
        disableOnInteraction: false, // Keeps autoplay running after user interaction
      }}
      loop={true} // Enable looping
      className="mySwiper"
      >
        <SwiperSlide>
         
          <br />
          <br />
          <h1 className="h1home">Launching the app on Telegram.</h1>
          <br />
          <div className="centeritall">
            <img src={one} style={{ width: '200px', borderRadius: '10px' }} alt="Slide 1" />
          </div>
          <br />
        </SwiperSlide>

        <SwiperSlide>
          <h1 className="h1home">Open the Memecoin Catcher GOTTON mini-app on Telegram.</h1>
          <br />
          <div className="centeritall">
            <img src={two} style={{ width: '200px', borderRadius: '10px' }} alt="Slide 2" />
          </div>
          <br />
        </SwiperSlide>

        <SwiperSlide>
        <h1 className="h1home">Play the game on the space where you landed on the board!</h1>   
                    <br/> 
                    <div className="centeritall">
                      <img src={p2}  style={{width:"220px" , borderRadius:"10px"}}/>
                      </div>  
                      <br/> 
   
            
        </SwiperSlide>


        <SwiperSlide>
        <h1 className="h1home">Clear the stage by destroying a set number of color blocks shown at the top of the screen. Try to clear it with the fewest moves and score higher!</h1>   
                    <br/> 
                    <div className="centeritall">
                      <img src={p3}  style={{width:"220px" , borderRadius:"10px"}}/>
                      </div>  
                      <br/> 
 

        </SwiperSlide>
        <SwiperSlide>

        <h1 className="h1home">
                    After clearing the game, your score and the reward points will be displayed. The top 1,000 players with the highest scores during a certain period will advance to the second stage.
                      </h1>   
                    <br/> 
                    <div className="centeritall">
                      <img src={p4}  style={{width:"200px" , borderRadius:"10px"}}/>
                      </div>  
                      <br/> 
    
        </SwiperSlide>
        <SwiperSlide>
        <h1 className="h1home">
                    As a sub-game, there's also a virtual claw game. In this game, you can  bet gold coins, which are needed to purchase items, and play to win even more gold coins!
                      </h1>   
                    <br/> 
                    <div className="centeritall">
                      <img src={p5}  style={{width:"200px" , borderRadius:"10px"}}/>
                      </div>  
                      <br/> 
    

        </SwiperSlide>
        <SwiperSlide>

        <h1 className="h1home">
                    Here’s the leaderboard. Make sure to check your ranking regularly!
                      </h1>   
                    <br/> 
                    <div className="centeritall">
                      <img src={p6}  style={{width:"200px" , borderRadius:"10px"}}/>
                      </div>  
                      <br/> 
     
        </SwiperSlide>
        <SwiperSlide>
        <h1 className="h1home">
                    In the second stage, which open to the top 1,000 players, you'll remotely control a real (actual) claw machine. If you successfully grab the target, you'll receive a trending memecoin that's dominating the market!
                      </h1>   
                    <br/> 
                    <div className="centeritall">
                      <img src={p7}  style={{width:"200px" , borderRadius:"10px"}}/>
                      </div>  
                      <br/> 
         


        </SwiperSlide>

      </Swiper>

     
    </div>
  );
}
