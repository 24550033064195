

import React, { useRef } from "react";
import { gsap, Power4 } from "gsap";
import "./navbar.css"; // Include your styles
import logo from './images/flip/mainlogo.png'


const App = () => {
  const menuContainer = useRef(null);
  const menuItems = useRef([]);
  const menuButton = useRef(null);
  const menuAnimation = useRef(null);
  const linkAnimation = useRef(null);
  const isMenuOpen = useRef(false);

 

  const toggleMenu = () => {
    if (!isMenuOpen.current) {
      menuAnimation.current.play();
      linkAnimation.current.play();
      menuButton.current.classList.remove("fa-bars");
      menuButton.current.classList.add("fa-times");
    } else {
      linkAnimation.current.reverse();
      menuAnimation.current.reverse();
      menuButton.current.classList.add("fa-bars");
      menuButton.current.classList.remove("fa-times");
    }
    isMenuOpen.current = !isMenuOpen.current;
  };

  React.useEffect(() => {
    // Initialize GSAP animations
    menuAnimation.current = gsap.to(menuContainer.current, {
      scaleX: 1,
      y: "100%",
      ease: Power4.easeInOut,
      duration: 2.2,
      paused: true,
    });

    linkAnimation.current = gsap.from(menuItems.current, {
      autoAlpha: 0,
      x: 200,
      stagger: 0.6,
      ease: Power4.easeInOut,
      duration: 1.2,
      paused: true,
    });
  }, []);

  return (
    <div>
      <div ref={menuContainer} className="menu-container">
      <nav style={{textDecoration:"none"}}>
          {[
            { label: "Twitter", href: "https://x.com/GOTTON_meme" },
            { label: "Telegram ", href: "https://t.me/GOTTON_meme" },
            { label: "Mini App", href: " https://t.me/b_mini_game_prod_bot" },
        
          ].map((item, index) => (
            <a
              href={item.href}
              className="menu-item"
              ref={(el) => (menuItems.current[index] = el)}
              key={index}
              style={{textDecoration:"none"}}
            >
              {item.label}
            </a>
          ))}
        </nav>
      </div>

      <header>
        <h1 className="logo">
<img src={logo} style={{width:"140px" , position:"relative" , top:"5px" , right:"15px"}}/>

        </h1>
        <i
          ref={menuButton}
          className="fa fa-bars menu-btn"
          id="menu-btn"
          onClick={toggleMenu}
        ></i>
      </header>
    </div>
  );
};

export default App;
