import React from 'react';
import './Roadmap.css';

function Roadmap() {
  return (
    <div className="container">
  <h1
        style={{ textAlign: "center", color: "#fff", marginBottom: "2rem" }}
        className="shimanofas"
      >
Roadmap
      </h1>    <br/>  <br/>  

      <div class="">
  <div class="">
    
    <div class="ag-courses_item">
      <a class="ag-courses-item_link">
        <div class="ag-courses-item_bg"></div>

        <div class="ag-courses-item_title">
        2024 later half of December
        </div>
        <br/>
      <h5 className='g1'>GOTTON Phase-1</h5>
      <br/>
      <>
        <p className='l1'> ➤ The TG puzzle game part will be open</p>

        <p className='l1'> ➤ Gamers will play the puzzle game and compete each other to reach
        higher score</p>

        <p className='l1'> ➤ 1M users</p>
      </>
      </a>
    </div>

    <div class="ag-courses_item">
      <a class="ag-courses-item_link">
        <div class="ag-courses-item_bg"></div>

        <div class="ag-courses-item_title">
        2025 middle of January
        </div>
        <br/>
      <h5 className='g1'>GOTTON Phase-2</h5>
      <br/>
      <>
        <p className='l1'> ➤ The Memecoin Catcher
GOTTON (physical claw
game part) will be open</p>

        <p className='l1'> ➤
The top 1,000 gamers will
have the right to challenge it</p>

        <p className='l1'> ➤ 2M users</p>
      </>
      </a>
    </div>







  </div>
</div>

    </div>
  );
}

export default Roadmap;
