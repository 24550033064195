import React from 'react'
import Marquee from "react-fast-marquee";
function Marquees() {
  return (
    <div className='backofitfeelglassym'>
        <Marquee>    &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 className='memec'> Memecoin</h4>


        &nbsp;

        <h4 className='memecs'> Catcher</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 className='memec'> Memecoin</h4>


        &nbsp;

        <h4 className='memecs'> Catcher</h4>

        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 className='memec'> Memecoin</h4>


        &nbsp;

        <h4 className='memecs'> Catcher</h4>


        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 className='memec'> Memecoin</h4>


        &nbsp;

        <h4 className='memecs'> Catcher</h4>


        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 className='memec'> Memecoin</h4>


        &nbsp;

        <h4 className='memecs'> Catcher</h4>


        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 className='memec'> Memecoin</h4>


        &nbsp;

        <h4 className='memecs'> Catcher</h4>


        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 className='memec'> Memecoin</h4>


        &nbsp;

        <h4 className='memecs'> Catcher</h4>


        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 className='memec'> Memecoin</h4>


        &nbsp;

        <h4 className='memecs'> Catcher</h4>


        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 className='memec'> Memecoin</h4>


        &nbsp;

        <h4 className='memecs'> Catcher</h4>


        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 className='memec'> Memecoin</h4>


        &nbsp;

        <h4 className='memecs'> Catcher</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 className='memec'> Memecoin</h4>


        &nbsp;

        <h4 className='memecs'> Catcher</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 className='memec'> Memecoin</h4>


        &nbsp;

        <h4 className='memecs'> Catcher</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 className='memec'> Memecoin</h4>


        &nbsp;

        <h4 className='memecs'> Catcher</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 className='memec'> Memecoin</h4>


        &nbsp;

        <h4 className='memecs'> Catcher</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;

        </Marquee>


    </div>
  )
}

export default Marquees