import React from 'react';
import heroVideo from './sm.mp4';

function Logos() {
  return (
    <div style={{ position: 'relative', height: '100vh', width: '100vw', overflow: 'hidden' }}>
      <video
        src={heroVideo}
        autoPlay
        loop
        muted
        playsInline
        style={{
          position: 'absolute',
          top: '7%',
          minWidth: '100%',
          minHeight: '100%',
          objectFit: 'cover',
        }}
      />

    </div>
  );
}

export default Logos;
