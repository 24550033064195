import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from './images/flip/mainlogo.png'
import './Buttonb.scss'


function CollapsibleExample() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className={`custom-navbar ${scrolled ? 'scrolled' : ''}`}
   
    >
      <>
        <Navbar.Brand href="#home" className="text-white">
        <img src={logo} style={{width:"170px" , position:"relative" , top:"5px" , right:"15px"}}/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="custom-nav"> {/* Custom class for right alignment */}
            <Nav.Link href="https://x.com/GOTTON_meme" className="text-whites">
            Twitter
            </Nav.Link>
            <Nav.Link eventKey={2} href="https://t.me/GOTTON_meme" className="text-whites">
            Telegram
            </Nav.Link>
            <Nav.Link eventKey={2} href="https://t.me/b_mini_game_prod_bot" className="text-whites">
            <a href=''>
        <button class="buttonb" type="button">
  <span class="button-outline">    
    <span class="button-shadow">
      <span class="button-inside">
        <span class="button-text visually-hidden"> MINI APP</span>
        <span class="button-text-characters-container" aria-hidden="true"></span>
      </span>
    </span>
  </span>
</button>
        </a>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </>
    </Navbar>
  );
}

export default CollapsibleExample;
