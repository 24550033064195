import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";

import Navbar from './NavbarDesktop'
import NavbarMobile from './NavbarMobile'


import Desktop from "./logos";
import DesktopMobile from "./MobileNavHome";

import mistVideo from './mist.mp4';



import About from './About';
import AboutMobile from './AboutMobile';

import Marquee from './Marquee';
import Footer from './Footer';
import FooterMobile from './FooterMobile';

import gsap from 'gsap';

const Home = () => {
  const [isLoading, setIsLoading] = useState(true); // Track loading state
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    // Trigger the transition after 3 seconds
    const timer = setTimeout(() => {
      const tl = gsap.timeline({
        onComplete: () => setIsLoading(false), // Remove loading screen after animation
      });

      // Animate video fade-out and website fade-in simultaneously
      tl.to(".mistVideo", { opacity: 0, duration: 2 }, 0) // Fade out video
        .to(".content", { opacity: 3, duration: 3 }, 0); // Fade in website content
    }, 500);

    return () => clearTimeout(timer); // Cleanup timeout
  }, []);

  return (
    <div className="home-container">
      {/* Mist Video */}
 

      {/* Main Content */}
      <div
        className="content"
        style={{ opacity: isLoading ? 0 : 1 }} // Start content as invisible
      >
        {!smallScreen ? (
          <div>
            <div className="HomepageAbouth mainfont">
            
              <Navbar/>
              <Desktop />
              <Marquee />
              <div className="HomepageAbouths">
                <About />
              </div>
              <Marquee />
              <Footer />
            </div>
          </div>
        ) : (
          <div className=" mobs">
            <NavbarMobile/>
            <DesktopMobile />
            <Marquee />
            <div className="HomepageAbouthsf">
              <AboutMobile />
            </div>
            <Marquee />
            <FooterMobile />
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
