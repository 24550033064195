import React, {  useRef } from "react";

import './Buttons.scss'

import Slide from './Slide'




function HowToBuy() {
  const sectionRef = useRef(null);






  return (
    <div className="container" id="how-to-play" ref={sectionRef}>
      
      <h1
        style={{ textAlign: "center", color: "#fff", marginBottom: "2rem" }}
        className="shimanofas"
      >
        How to Play
      </h1>   
      
       <br/>  <br/>  <br/>  
     <Slide/>
   <br/><br/><br/>
<div className="centeritall">
<a href=''>
        <button class="buttonz" type="button">
  <span class="button-outline">    
    <span class="button-shadow">
      <span class="button-inside">
        <span class="button-text visually-hidden">Coming Soon!</span>
        <span class="button-text-characters-container" aria-hidden="true"></span>
      </span>
    </span>
  </span>
</button>
        </a>

</div>
<br/>   <br/>
    </div>
  );
}

export default HowToBuy;
