import React, { useEffect, useRef } from 'react';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';



import Slider from './SliderMobile';
import Burn from './Burn'
import Roadmap from './Roadmap';

gsap.registerPlugin(ScrollTrigger);

function Footer() {
  const textRef = useRef(null);
  const headerRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    // Ensure animations work after the DOM is fully ready
    if (textRef.current && headerRef.current && imageRef.current) {

      // GSAP Color change for the text on scroll
      gsap.fromTo(
        textRef.current,
        { color: '#000' },
        {
          color: '#fff',
          scrollTrigger: {
            trigger: textRef.current,
            start: 'top 80%',
            end: 'top 30%',
            scrub: true,
          },
        }
      );

      // Header fade-in animation
      gsap.fromTo(
        headerRef.current,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          scrollTrigger: {
            trigger: headerRef.current,
            start: 'top 80%',
            end: 'top 50%',
            scrub: true,
          },
        }
      );

      // Image animation - fade in and scale
      gsap.from(imageRef.current, {
        opacity: 0,
        scale: 0.9,
        duration: 1.5,
        ease: 'power4.out',
        scrollTrigger: {
          trigger: imageRef.current,
          start: 'top 85%',
          end: 'top 50%',
          scrub: true,
        },
      });
    }
  }, []);

  return (
    <div className='container'>
    

      <br />
      <br /> 
      <br />
    <Slider/>
    <br />
<br />
<br /> 

<Roadmap/>
      <br />
      <br /> 
      <br />
    </div>
  );
}

export default Footer;
