import React from 'react';
import heroVideo from './main.mp4';

function Logos() {
  return (
    <div style={{ position: 'relative', height: '100vh', width: '100vw', overflow: 'hidden' }}>
      <video
        src={heroVideo}
        autoPlay
        loop
        muted
        playsInline
        style={{
          position: 'absolute',
 
          minWidth: '100%',
          minHeight: '100%',
          objectFit: 'cover',
        }}
      />
    </div>
  );
}

export default Logos;
