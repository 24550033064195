// Twitter.js
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Twitter() {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to the external link
    window.location.href = "https://x.com/GOTTON_meme";
  }, [navigate]);

  // Optional: You can return null or a simple message to indicate redirection is happening
  return <p>Redirecting to Twitter...</p>;
}

export default Twitter;
